// model
import {
  findProjectOperation,
  projectOperation
} from "@/model/workflow/project/projectModel";

// mixins
import { snackbarableMixin } from "@/mixins/shared/snackbarable/snackbarableMixin";
import { alertableMixin } from "@/mixins/shared/alertable/alertableMixin";
import { menuItemSettingsMixin } from "@/mixins/shared/base/settings/menuItemSettingsMixin";
import {
  createActionResultError,
  createActionResultSuccess
} from "@/model/action/actionModel";

export const projectMixin = {
  mixins: [snackbarableMixin, alertableMixin, menuItemSettingsMixin],
  components: {
    ProjectLayout: () => import("@/components/project/ProjectLayout"),
    ProjectActions: () => import("@/components/project/ProjectActions"),
    BaseMenuItem: () => import("@/components/shared/base/BaseMenuItem"),
    ProjectOperationDialog: () =>
      import("@/components/project/dialogs/ProjectOperationDialog.vue"),
    ProjectDetailedReportDialog: () =>
      import("@/components/project/dialogs/ProjectDetailedReportDialog.vue"),
    AssignManagerDialog: () =>
      import("@/components/project/dialogs/AssignManagerDialog.vue")
  },
  props: {
    /**
     * Workflow Project
     * @type {{projectId: Number, projectName:string, definitionId: Number, workflowInstanceId: Number, workflowTypeId: Number, recordId: Number, subject: String, owner: String, userName:string, projectManagerId: Number, templateId: Number, templateName: String, projectStatus: number, isOverdue: Boolean, isSuspended: false, canAccess: Boolean, canCancel: Boolean, canChangeOwnership: Boolean, canReassign: Boolean, canSuspend: Boolean, comment:string, durableInstanceUid:string, creationDate:string}}
     */
    project: undefined
  },
  data() {
    return {
      projectOperation: projectOperation,
      visibleDialogDeleteProject: false,
      visibleDialogCancelProject: false,
      visibleDialogSuspendProject: false,
      visibleDialogResumeProject: false,
      visibleDialogProjectExport: false,
      visibleDialogAssignProject: false
    };
  },
  computed: {
    /**
     * Get Workflow Project projectId
     * @return {Number|number} Workflow Project id
     */
    projectId() {
      return this.project?.projectId ?? -1;
    },

    /**
     * Get Workflow Project Name
     * @return {String|string} Workflow Project Name
     */
    projectName() {
      return this.project?.projectName ?? "";
    }
  },
  methods: {
    /**
     * Assign Manager
     * Abstract method
     * @param {{ projectId: Number, managerId: Number, comment: String, sendNotification: Boolean}} payload
     * @return {Promise<{Boolean}>}
     */
    assignManager(payload) {
      console.warn(
        "Not implemented Abstract method: assignManager() ",
        payload
      );
    },

    /**
     * Abstract Update Project Manager
     * @param {{id: Number, managerName: String}} payload
     */
    updateProjectManager(payload) {
      console.warn(`Abstract updateProjectManager() payload:`, payload);
    },
    /**
     * handle callback function call: onProjectExport
     */
    onProjectExport() {
      this.visibleDialogProjectExport = true;
    },

    /**
     * handle callback function call: onProjectAssignManager
     */
    onProjectAssignManager() {
      this.visibleDialogAssignProject = true;
    },

    /**
     * handle callback function call: onProjectRepair
     */
    onProjectRepair() {
      this.showSnackbarWarning(
        `TODO: Repair Project: ${this.projectName}`,
        true
      );
    },

    /**
     * handle callback function call: onProjectResume
     */
    onProjectResume() {
      this.visibleDialogResumeProject = true;
    },

    /**
     * handle callback function call: onProjectCancel
     */
    onProjectCancel() {
      this.visibleDialogCancelProject = true;
    },

    /**
     * handle callback function call: onProjectDelete
     */
    onProjectDelete() {
      this.visibleDialogDeleteProject = true;
    },

    /**
     * handle callback function call: onProjectSuspend
     */
    onProjectSuspend() {
      this.visibleDialogSuspendProject = true;
    },

    /**
     * handle close Dialog Delete Project
     */
    closeDialogDeleteProject() {
      this.visibleDialogDeleteProject = false;
    },

    /**
     * handle close Dialog Cancel Project
     */
    closeDialogCancelProject() {
      this.visibleDialogCancelProject = false;
    },

    /**
     * handle close Dialog Suspend Project
     */
    closeDialogSuspendProject() {
      this.visibleDialogSuspendProject = false;
    },

    /**
     * handle close Dialog Resume Project
     */
    closeDialogResumeProject() {
      this.visibleDialogResumeProject = false;
    },

    /**
     * handle close Dialog Export Project
     */
    closeDialogExportProject() {
      this.visibleDialogProjectExport = false;
    },

    /**
     * handle close Dialog Assign Manager
     */
    closeDialogAssignManager() {
      this.visibleDialogAssignProject = false;
    },

    /**
     * Event On Assign Manager
     * @param {String} managerName
     * @param {Number} managerId
     * @param {String} comment
     * @param {Boolean} notify
     * @return {Promise<{type: string, message: string, outcome: string}>}
     */
    async onManagerAssignment(managerName, managerId, comment, notify) {
      try {
        const operation = findProjectOperation(
          projectOperation.ReassignManager
        );
        const action = `${operation?.label}`;

        const payload = {
          projectId: this.projectId ?? -1,
          managerId: managerId ?? -1,
          comment: comment ?? "",
          sendNotification: notify ?? false
        };
        const result = await this.assignManager(payload);
        if (result?.data) {
          const payload = {
            id: this.projectId ?? -1,
            managerName: managerName
          };
          this.updateProjectManager(payload);
        } else {
          return createActionResultError("Project Manager Assignment");
        }
        return createActionResultSuccess(action);
      } catch (e) {
        return createActionResultError(e?.toString());
      }
    }
  }
};
